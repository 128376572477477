import { useActor, useSelector } from "@xstate/react";
import React, { useCallback, useContext, useState } from "react";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { MeasurementSystem } from "../../../types/graphql-global-types";
import { Locale } from "../../../types/Locale";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { UnitSwitcher } from "../components/UnitSwitcher";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingStepProps } from "../types";
import { changeUnit } from "../utils";
import { measurementUnitSelector } from "./AgeHeightStep";

export function MeasurementSystemStep({ onDone }: OnboardingStepProps) {
  const { locale } = useContext(pageContext);
  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);
  const measurementUnit = useSelector(onboarding.machine!, measurementUnitSelector);

  const initialUnit = locale === Locale.SV ? MeasurementSystem.METRIC : measurementUnit;

  const [unit, setUnit] = useState<MeasurementSystem>(initialUnit ?? MeasurementSystem.IMPERIAL);

  const tt = useOnboardingTranslations();

  const handleClick = () => {
    send({
      type: "SET_PAYLOAD",
      payload: "measurementSystem",
      value: unit,
    });
    onDone?.();
  };

  const handleChangeUnit = useCallback(() => {
    const selectedUnit = changeUnit(unit);
    setUnit(selectedUnit);
  }, [unit, setUnit]);

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.measurementSystemStep.heading} />
      <div className="w-40 mx-auto">
        <UnitSwitcher switchType="length" onChange={handleChangeUnit} unit={unit} />
      </div>
      <OnboardingButton label={tt.buttonDone} onClick={handleClick} marginTop="mt-12" />
    </div>
  );
}
