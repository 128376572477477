import { FaRegEdit } from "@react-icons/all-files/fa/FaRegEdit";
import React from "react";
import { OnboardingStepProps } from "../onboarding/types";

export function PreferenceSetting({
  title,
  value,
  icon,
  onEdit,
}: {
  title: string;
  icon: string;
  value?: string;
  onEdit: () => void;
}) {
  return (
    <>
      <div className="shrink-0 pr-6 flex items-center">
        <img src={icon} className="w-8" />
      </div>
      <div className="grow w-full">
        <h3 className="m-0 text-3xl">{title}</h3>
        <p className="m-0 min-h-[1.75rem] text-xl">{value}</p>
      </div>
      <div className="shrink-0 flex items-center pl-6">
        <FaRegEdit className="w-6 h-6 cursor-pointer hover:opacity-50" onClick={onEdit} />
      </div>
    </>
  );
}

export function PreferencePortal({
  PreferenceUI,
  onDone,
}: {
  PreferenceUI?: React.FunctionComponent<OnboardingStepProps>;
  onDone: () => void;
}) {
  return (
    <div className="flex fixed px-2 justify-center items-center z-9999 top-0 left-0 w-full h-full bg-black bg-opacity-80">
      <div className="bg-white px-12 py-6 rounded-md shadow-2xl max-w-2xl">
        {PreferenceUI && <PreferenceUI onDone={onDone} />}
      </div>
    </div>
  );
}
