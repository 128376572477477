import { useActor, useSelector } from "@xstate/react";
import React, { useContext, useMemo, useState } from "react";
import { MeasurementSystem } from "../../../types/graphql-global-types";
import { InputField } from "../components/InputField";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingMachineState, OnboardingStepProps } from "../types";
import { useUserHeight } from "../useUserHeight";

const heightSelector = (state: OnboardingMachineState) => state.context.onboardingPayload.heightCm;
const measurementSystemSelector = (state: OnboardingMachineState) =>
  state.context.onboardingPayload.measurementSystem;

export function HeightStep({ onDone }: OnboardingStepProps) {
  const tt = useOnboardingTranslations();
  const onboarding = useContext(OnboardingStateContext);
  const [, send] = useActor(onboarding.machine!);
  const heightCmInit = useSelector(onboarding.machine!, heightSelector);
  const measurementSystem = useSelector(onboarding.machine!, measurementSystemSelector);

  const isImperial = useMemo(() => measurementSystem === MeasurementSystem.IMPERIAL, [measurementSystem]);

  const { heightCm, heightFt, heightIn, calculateHeight } = useUserHeight(heightCmInit);

  const [heightInCm, setHeightInCm] = useState(heightCm);
  const [heightInFeet, setHeightInFeet] = useState(heightFt);
  const [heightInInches, setHeightInInches] = useState(heightIn);
  const isValid = useMemo(
    () => (isImperial && heightInFeet + heightInInches > 0) || (!isImperial && heightInCm > 0),
    [heightInCm, heightInFeet, heightInInches, isImperial]
  );

  const handleClick = () => {
    if (isValid) {
      const value = calculateHeight(
        isImperial ? MeasurementSystem.IMPERIAL : MeasurementSystem.METRIC,
        heightInCm,
        heightInFeet,
        heightInInches
      );

      send({
        type: "SET_PAYLOAD",
        payload: "heightCm",
        value,
      });
      onDone?.();
    }
  };

  const nextLabel = useMemo(
    () => (onDone ? tt.buttonDone : tt.buttonNext),
    [onDone, tt.buttonDone, tt.buttonNext]
  );

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.heightStep.heading} />
      <div className="w-40 mx-auto">
        <div className="flex flex-col">
          <div className="grid grid-flow-col gap-2">
            {isImperial && (
              <InputField
                value={String(Math.floor(heightInFeet))}
                min={0}
                inputType="text"
                placeholderText="Ft"
                pattern="\d*"
                maxLength={2}
                onChange={(event) =>
                  setHeightInFeet(event.target.validity.valid ? Number(event.target.value) : heightInFeet)
                }
              />
            )}
            <InputField
              value={String(Math.floor(isImperial ? heightInInches : heightInCm))}
              min={0}
              inputType="text"
              placeholderText={isImperial ? "In" : "cm"}
              pattern="\d*"
              maxLength={isImperial ? 2 : 3}
              onChange={(event) => {
                if (isImperial) {
                  const val = event.target.validity.valid ? Number(event.target.value) : heightInInches;
                  setHeightInInches(val);
                } else {
                  const val = event.target.validity.valid ? Number(event.target.value) : heightInCm;
                  setHeightInCm(val);
                }
              }}
            />
          </div>
          <div className="flex justify-center mt-4">{isImperial ? "In" : "cm"}</div>
        </div>
      </div>

      <OnboardingButton disabled={!isValid} label={nextLabel} onClick={handleClick} marginTop="mt-6" />
    </div>
  );
}
