import { t } from "@lingui/macro";
import React from "react";

export function UpdatePreferencesNote() {
  const tt = useTranslations();
  return <p className="italic">{tt.message}</p>;
}

function useTranslations() {
  return {
    message: t({
      id: "User.updatePreferences.message",
      message:
        "Updating your preferences will not alter your current meal plans — only future ones. If you wish to update all of your current personalized meal plans, please delete the existing ones first.",
    }),
  };
}
