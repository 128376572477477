/* eslint-disable react-hooks/rules-of-hooks */
import { t } from "@lingui/macro";
import { useActor, useMachine, useSelector } from "@xstate/react";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { assign, createMachine } from "xstate";
import emoji_active from "../../../static/images/emojis/active.png";
import emoji_age from "../../../static/images/emojis/age.png";
import emoji_conditions from "../../../static/images/emojis/conditions.png";
import emoji_gender from "../../../static/images/emojis/gender.png";
import emoji_height from "../../../static/images/emojis/height.png";
import emoji_weight from "../../../static/images/emojis/weight.png";
import { useNavigate } from "../../components/Link/Link";
import { LoginContext } from "../../components/LoginProvider/LoginProvider";
import { pageContext } from "../../components/PageProvider/PageProvider";
import { SEOReactHelmet } from "../../components/SEO/SEOReactHelmet";
import { OnboardingStateContext, OnboardingStateProvider } from "../onboarding/OnboardingStateProvider";
import {
  activityLevelSelector,
  ActivityLevelStep,
  getOptions as getActivityLevelOptions,
} from "../onboarding/steps/ActivityLevelStep";
import {
  birthYearSelector,
  heightCmSelector,
  measurementUnitSelector,
} from "../onboarding/steps/AgeHeightStep";
import { AgeStep } from "../onboarding/steps/AgeStep";
import { genderSelector, GenderStep, getOptions as getGenderOptions } from "../onboarding/steps/GenderStep";
import HealthConditionStep, {
  getOptions as getHealthConditionOptions,
  healthConditionsSelector,
} from "../onboarding/steps/HealthConditionStep";
import { HeightStep } from "../onboarding/steps/HeightStep";
import { MeasurementSystemStep } from "../onboarding/steps/MeasurementSystemStep";
import { OnboardingTranslation, useOnboardingTranslations } from "../onboarding/translations";
import { OnboardingStepProps } from "../onboarding/types";
import { useUserHeight } from "../onboarding/useUserHeight";
import { LoginPage } from "../../pages";
import { MeasurementSystem } from "../../types/graphql-global-types";
import { isBrowser } from "../../utils/ssr";
import { capitalizeFirstLetter } from "../../utils/string";
import { PreferencePortal, PreferenceSetting } from "./PreferenceSetting";
import { UpdatePreferencesNote } from "./UpdatePreferencesNote";

const machine = createMachine(
  {
    predictableActionArguments: true,
    initial: "idle",
    context: {
      value: undefined,
      step: undefined,
      extraProps: undefined,
    },
    states: {
      idle: {
        on: {
          EDIT: {
            target: "editing",
            actions: ["edit"],
          },
        },
      },
      editing: {
        on: {
          DONE: "idle",
        },
      },
    },
  },
  {
    actions: {
      edit: assign((_, e: any) => ({
        value: e.initValue,
        step: e.step,
        extraProps: e.extraProps,
      })),
    },
  }
);

// ts-prune-ignore-next
export default function PersonalSettingsPage() {
  if (!isBrowser()) {
    return null;
  }

  const { locale } = useContext(pageContext);
  const tt = useTranslations();

  return (
    <>
      <SEOReactHelmet title={tt.seoTitle} description={tt.seoDescription} lang={locale as string} />
      <OnboardingStateProvider>
        <PersonalSettingsPageComponent />
      </OnboardingStateProvider>
    </>
  );
}

function PersonalSettingsPageComponent() {
  const { locale } = useContext(pageContext);
  const onboarding = useContext(OnboardingStateContext);
  const [, sendToOnboardingMachine] = useActor(onboarding.machine!);
  const [state, send] = useMachine(machine);
  const isEditing = useMemo(() => state.matches("editing"), [state]);
  const StepUI = useMemo(() => state.context.step, [state]);
  const navigate = useNavigate();
  const { loading: authLoading, user: userData } = useContext(LoginContext);

  const gender = useSelector(onboarding.machine!, genderSelector);
  const birthYear = useSelector(onboarding.machine!, birthYearSelector);
  const activityLevel = useSelector(onboarding.machine!, activityLevelSelector);
  const healthConditions = useSelector(onboarding.machine!, healthConditionsSelector);
  const measurementSystem = useSelector(onboarding.machine!, measurementUnitSelector);
  const heightInCm = useSelector(onboarding.machine!, heightCmSelector);

  useEffect(() => {
    if (!authLoading && !userData) {
      navigate({ to: LoginPage, query: { redirectTo: window.location.href } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLoading]);

  const tt = useTranslations();
  const ott = useOnboardingTranslations();

  const genderValue = useMemo(
    () => getGenderOptions(ott).find((o) => o.option === gender)?.label,
    [ott, gender]
  );

  const activityLevelValue = useMemo(
    () => getActivityLevelOptions(ott).find((o) => o.option === activityLevel)?.label,
    [ott, activityLevel]
  );

  const healthConditionValue = useMemo(() => {
    return healthConditions?.length === 0
      ? ott.buttonNone
      : healthConditions
          ?.map((c) => getHealthConditionOptions(ott).find((o) => o.option === c)?.label)
          .join(", ");
  }, [ott, healthConditions]);

  const isImperial = measurementSystem === MeasurementSystem.IMPERIAL;
  const { heightCm, heightFt, heightIn } = useUserHeight(heightInCm);
  const heightValue = isImperial ? `${heightFt}${heightIn} In` : `${heightCm} cm`;

  const onEdit: (step: React.FunctionComponent<OnboardingStepProps>) => () => void = useCallback(
    (step) => () => {
      send({ type: "EDIT", step });
    },
    [send]
  );
  const onDone = useCallback(() => {
    send({ type: "DONE" });
    sendToOnboardingMachine({ type: "UPDATE_USER_PREFERENCES" });
  }, [send, sendToOnboardingMachine]);

  return (
    <>
      <SEOReactHelmet title={tt.seoTitle} description={tt.seoDescription} lang={locale as string} />
      {isEditing && <PreferencePortal PreferenceUI={StepUI} onDone={onDone} />}
      <div className="max-w-screen-xl mx-auto w-full px-6 pb-24">
        <section className="w-full mt-l ">
          <div className="flex flex-row justify-between items-center">
            <h2 className="mx-0 my-8">{tt.settingsTitle}</h2>
          </div>
        </section>
        <section className="w-full mt-l ">
          <ul className="p-0 flex flex-col w-full gap-8">
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_gender}
                title={tt.settingGender}
                value={genderValue}
                onEdit={onEdit(GenderStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_age}
                title={tt.settingBirthYear}
                value={birthYear?.toString() ?? ""}
                onEdit={onEdit(AgeStep)}
              />
            </li>

            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_weight}
                title={tt.settingMeasurementSystem}
                value={toMeasurementSystemStr(ott, measurementSystem ?? MeasurementSystem.IMPERIAL)}
                onEdit={onEdit(MeasurementSystemStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_height}
                title={tt.settingHeight}
                value={heightValue}
                onEdit={onEdit(HeightStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_active}
                title={tt.settingActivityLevel}
                value={activityLevelValue}
                onEdit={onEdit(ActivityLevelStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_conditions}
                title={tt.settingHealthConditionStep}
                value={healthConditionValue}
                onEdit={onEdit(HealthConditionStep)}
              />
            </li>
          </ul>
        </section>
        <section>
          <UpdatePreferencesNote />
        </section>
      </div>
    </>
  );
}

function useTranslations() {
  return {
    seoTitle: t({
      id: "User.seoTitle",
      message: "Personal settings - Diet Doctor",
    }),
    seoDescription: t({
      id: "User.seoDescription",
      message: "Personal settings",
    }),
    settingsTitle: t({
      id: "User.settingsTitle",
      message: "Personal settings",
    }),
    settingGender: t({
      id: "User.setting.gender",
      message: "What sex best describes you?",
    }),
    settingBirthYear: t({
      id: "User.setting.birthYear",
      message: "Birth year",
    }),
    settingMeasurementSystem: t({
      id: "User.setting.measurementSystem",
      message: "Measurement system",
    }),
    settingHeight: t({
      id: "User.setting.height",
      message: "Height",
    }),
    settingActivityLevel: t({
      id: "User.setting.activityLevel",
      message: "How physically active are you?",
    }),
    settingHealthConditionStep: t({
      id: "User.setting.activityLevelStep",
      message: "Do you have any of these health conditions?",
    }),
  };
}

function toMeasurementSystemStr(tt: OnboardingTranslation, measurementSystem: MeasurementSystem) {
  return measurementSystem === MeasurementSystem.IMPERIAL
    ? capitalizeFirstLetter(tt.measurementUnitImperial)
    : capitalizeFirstLetter(tt.measurementUnitMetric);
}
