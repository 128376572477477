import { useActor, useSelector } from "@xstate/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { InputField } from "../components/InputField";
import { OnboardingButton } from "../components/OnboardingButton";
import { OnboardingTitle } from "../components/OnboardingTitle";
import { OnboardingStateContext } from "../OnboardingStateProvider";
import { useOnboardingTranslations } from "../translations";
import { OnboardingMachineState, OnboardingStep, OnboardingStepProps } from "../types";
import { navigateToNextStep } from "../utils";

const birthYearSelector = (state: OnboardingMachineState) => state.context.onboardingPayload.birthYear;

export function AgeStep({ onDone }: OnboardingStepProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [disabled, setDisabled] = useState(false);
  const onboarding = useContext(OnboardingStateContext);
  const birthYearValue = useSelector(onboarding.machine!, birthYearSelector);
  const [, send] = useActor(onboarding.machine!);
  const tt = useOnboardingTranslations();
  const [birthYear, setBirthYear] = useState(birthYearValue ? birthYearValue.toString() : "");

  useEffect(() => {
    send({ type: "SET_STEP", step: OnboardingStep.HEIGHT_AGE });
  }, [send]);

  const nextLabel = useMemo(
    () => (onDone ? tt.buttonDone : tt.buttonNext),
    [onDone, tt.buttonDone, tt.buttonNext]
  );

  const handleClick = () => {
    if (birthYear !== "") {
      send({ type: "SET_PAYLOAD", payload: "birthYear", value: Number(birthYear) });
    }
    if (onDone) {
      onDone?.();
    } else {
      navigateToNextStep(OnboardingStep.HEIGHT_AGE);
    }
  };

  const year = useMemo(() => new Date().getFullYear(), []);

  useEffect(() => {
    const num = Number(birthYear);
    setDisabled(!(num > 1900 && num < year));
  }, [birthYear, year]);

  return (
    <div className="flex flex-col items-center">
      <OnboardingTitle title={tt.ageStep.heading} />
      <div className="w-40 mx-auto mt-10">
        <div className="flex flex-col mb-10">
          <label className="label-xxl text-center mb-3">{tt.ageStep.birthYear}</label>
          <InputField
            ref={ref}
            min={1900}
            max={year}
            value={birthYear}
            placeholderText={tt.ageStep.yearPlaceholder}
            inputType="text"
            pattern="\d*"
            maxLength={4}
            onChange={(event) =>
              setBirthYear((value) => (event.target.validity.valid ? event.target.value : value))
            }
            focus={true}
          />
        </div>
      </div>

      <OnboardingButton disabled={disabled} label={nextLabel} onClick={handleClick} marginTop="mt-12" />
    </div>
  );
}
